<template>
 <div>
   <v-container class="mt-5">
    <v-card elevation="0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-slide-x-transition>
              <div v-show="isShowGroup1" class="pt-5 mt-5 verticle-center" id="sec-1">
                <v-card elevation="0">
                  <div class="primary--text display-title-1" >{{$t('shortenAddressTitle')}}</div>
                  <v-btn class="text-none mt-4 mb-4 rounded-lg" block color="primary" @click="redirectTo('/painel/links/shortened')">
                    <v-icon class="mr-2">mdi-google</v-icon>
                    {{ $t('shortenFree') }}
                  </v-btn>
                  <v-card-actions class="text-description-shorten-link-1">
                   <v-spacer/>
                   <p>
                     {{$t('shortenAddressInfo')}}
                     <a href="/whats-new" class="link">
                       {{$t('learnMore')}}.
                     </a>
                   </p>
                   <v-spacer/>
                 </v-card-actions>
                </v-card>
             </div>
           </v-slide-x-transition>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="1"/>
          <v-col cols="12" xs="12" sm="12" md="5">
            <div class="text-right verticle-center">
              <v-img src="/images/woman-home.png" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
     <v-row>
      <v-col>
        <v-card class="card-blue pb-4 mt-4" dark :color="`#0079F1`" elevation="0">
          <v-card-title :class="`display-title-2`">
            <v-spacer/>
            <div class="title-shorten-links-benefts">
              {{$t('shortenAddressMoreTitle')}}
            </div>
            <v-spacer/>
          </v-card-title>
          <v-row no-gutters>
            <v-col cols="2"/>
            <v-col>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card color="white" class="mr-2 ml-2 url-shorten-bonus" elevation="0">
                    <v-card-title>
                      <v-spacer/>
                      <v-card outlined elevation="0" light class="business-link-1">
                        <v-card-actions class="primary--text business-link-text-1">
                          <v-icon size="2.19975rem" class="mr-2" color="#7E8EA6">mdi-link-variant</v-icon>
                          <small>migly.in/<b>suamarca</b></small>
                        </v-card-actions>
                      </v-card>
                      <v-spacer/>
                    </v-card-title>
                    <v-card-title class="primary--text mb-5 pb-5">
                      <v-spacer/>
                      <b>{{ $t('shortenAddressBenefit1') }}</b>
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card color="white" class="url-shorten-bonus mr-2 ml-2" elevation="0">
                    <v-card-title>
                      <v-spacer/>
                      <v-card-actions class="primary--text business-link-text-2">
                        <v-icon size="4.4375rem" class="mr-2" color="#7E8EA6">mdi-gesture-tap</v-icon>
                        <b class="mt-5 mr-2 ">1</b><b class="mt-3 mr-2">2</b><b class="mt-1 mr-2">3</b>
                      </v-card-actions>
                      <v-spacer/>
                    </v-card-title>
                    <v-card-title class="primary--text">
                      <v-spacer/>
                      <b>{{ $t('shortenAddressBenefit2') }}</b>
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card color="white" class="url-shorten-bonus mr-2 ml-2" elevation="0">
                    <v-card-title>
                      <v-spacer/>
                      <v-card-actions class="primary--text">
                        <v-card outlined class="mr-2 pa-1" light>
                          <v-icon size="70" color="grey">mdi-image</v-icon>
                        </v-card>
                        <v-skeleton-loader type="list-item-two-line" width="200" light/>
                      </v-card-actions>
                      <v-spacer/>
                    </v-card-title>
                    <v-card-title class="primary--text">
                      <v-spacer/>
                      <center>
                          <b>{{ $t('shortenAddressBenefit3') }}</b>
                      </center>
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card color="white" class="url-shorten-bonus mr-2 ml-2" elevation="0">
                    <v-card-title>
                      <v-spacer/>
                      <v-card-actions class="primary--text">
                        <v-icon size="70" class="mr-2 pa-1" color="grey">mdi-google-analytics</v-icon>
                        <b class="mt-2 mr-2">google analytics</b>
                      </v-card-actions>
                      <v-spacer/>
                    </v-card-title>
                    <v-card-title class="primary--text">
                      <v-spacer/>
                      <center>
                        <b>{{ $t('shortenAddressBenefit4') }}</b>
                      </center>
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"/>
          </v-row>
          <v-row v-if="false">
            <v-col cols="1" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"/>
            <v-col cols="12" xs="12" sm="12" md="3">
             <v-slide-x-transition>
               <center v-if="isShowGroup1">
                 <v-icon x-large  color="primary">
                   mdi-instagram
                 </v-icon>
                 <v-icon x-large color="primary">
                   mdi-whatsapp
                 </v-icon>
                 <br/>
                 <v-icon x-large color="primary">
                   mdi-facebook
                 </v-icon>
               </center>
             </v-slide-x-transition>
             <v-fade-transition>
               <center v-if="isShowGroup1" class="text-description-1 text-center"><p class="font-weight-bold">{{$t('shortenUrlBenefit1')}}</p></center>
             </v-fade-transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
             <v-slide-y-transition>
               <center v-if="isShowGroup1">
                 <v-icon x-large color="primary">
                   mdi-bio
                 </v-icon>
                 <br/>
                 <v-icon x-large  color="primary">
                   mdi-briefcase
                 </v-icon>
                 <v-icon x-large color="primary">
                   mdi-facebook-workplace
                 </v-icon>
               </center>
             </v-slide-y-transition>
             <v-fade-transition>
               <center v-if="isShowGroup1" class="text-description-1 text-center"><p class="font-weight-bold">{{$t('shortenUrlBenefit2')}}</p></center>
             </v-fade-transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
             <v-slide-x-reverse-transition>
               <center v-if="isShowGroup1">
                 <v-icon x-large color="primary">
                   mdi-chart-bubble
                 </v-icon>
                 <v-icon x-large  color="primary">
                   mdi-chart-donut
                 </v-icon>
                 <br/>
                 <v-icon x-large color="primary">
                   mdi-chart-donut-variant
                 </v-icon>
               </center>
             </v-slide-x-reverse-transition>
             <v-fade-transition>
               <center v-if="isShowGroup1" class="text-description-1 text-center"><p class="font-weight-bold">{{$t('shortenUrlBenefit3')}}</p></center>
             </v-fade-transition>
            </v-col>
            <v-col cols="1" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"/>
          </v-row>
        </v-card>
      </v-col>
     </v-row>
   </v-container>
   <v-slide-y-transition v-if="false">
      <section id="sec-1" class="grey lighten-3" v-show="isShowGroup1">
        <v-container>
          <v-card>
            <v-card-text>
              <UrlShortener :screenData="screenData"/>
            </v-card-text>
          </v-card>
        </v-container>
      </section>
   </v-slide-y-transition>
   <section id="sec-5" v-if="false">
    <v-container class="mt-5">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" v-if="!['xs'].includes($vuetify.breakpoint.name)">
            <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
              <div class="mobile-preview-container">
                <InfoproductView :infoproduct="infoProductEditData.infoproduct" :edit="false" :view="'xs'" :onlyView="true" />
              </div>
            </v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-card-text class="verticle-center text-right">
              <v-slide-x-transition>
                <div v-show="isShowGroup1">
                  <v-card-actions class="title-desc-sms" >
                    <v-spacer/>
                    <h3><b>{{$t('infoprodutosAndLeeds')}}</b></h3>
                  </v-card-actions>
                  <v-card-text class="text-description-1 text-right">
                    {{$t('infoprodutosAndLeedsTextInfo')}}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn dark color="primary" @click="redirectTo('/painel/nfoproduct')" rounded>
                      {{$t('createYourLeedPage')}}
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-slide-x-transition>
            </v-card-text>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" v-if="['xs'].includes($vuetify.breakpoint.name)">
            <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
              <div class="mobile-preview-container">
                <InfoproductView :infoproduct="infoProductEditData.infoproduct" :edit="false" :view="'xs'" :onlyView="true" v-if="infoProductEditData.infoproduct"/>
              </div>
            </v-img>
          </v-col>
        </v-row>
     </v-container>
   </section>
   <div id="sec-3">
     <v-container class="mt-5">
      <v-card-text>
        <SubscriptionAndPlans :screenData="screenData"/>
      </v-card-text>
     </v-container>
   </div>
   <v-slide-y-transition>
      <section id="sec-4" v-show="isShowGroup1" v-if="false">
        <v-container>
          <v-card elevation="0" color="#78FFFF" class="card-blue mt-4">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-card-text class="verticle-center">
                  <v-slide-x-transition>
                    <div v-show="isShowGroup1">
                      <v-card-actions class="title-desc-sms" ><h3><b>{{$t('sendSms')}}</b></h3></v-card-actions>
                      <v-card-actions class="text-description-1">
                        {{$t('sendSmsTextInfo')}}
                      </v-card-actions>
                      <carousel :autoplay="true" :perPage="3" v-if="false">
                        <slide v-for="item in $t('smsCarrosel')" :key="item.title">
                          <v-card width="170" class="ma-2" color="blue" style="padding: 1px;">
                            <v-card elevation="0">
                              <v-card-title>
                                <v-icon x-large color="blue">{{ item.icon }}</v-icon>
                                <v-spacer/>
                                <v-icon x-small v-if="item.comingSoon" color="primary">{{ $t('comingSoon') }}</v-icon>
                              </v-card-title>
                              <v-card-subtitle>{{ item.title }}</v-card-subtitle>
                            </v-card>
                          </v-card>
                        </slide>
                      </carousel>
                    </div>
                  </v-slide-x-transition>
                </v-card-text>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <center>
                  <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
                    <div class="mobile-preview-container">
                      <v-card-title>
                        <v-icon>mdi-chevron-left</v-icon>
                        <v-spacer/>
                        <v-avatar >
                          <v-icon large>mdi-account-circle</v-icon>
                        </v-avatar>
                        <v-spacer/>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-card-title>
                      <v-card-text>
                        <v-card elevation="0" outlined>
                          <v-card-text>
                            Gostaria de receber SMS do MIGLY? Acesse migly.in/i/xjb p/ receber. Ate aceitar, nao enviaremos mais. Taxas de dados podem ser aplicadas ao responder
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                      <center><small><small class="grey--text">{{ new Date().toGMTString()}}</small></small></center>
                      <v-card-text>
                        <v-card elevation="0" outlined>
                          <v-card-text>
                            MIGLY: Economize de 25 a 50% em itens em promocao em loja.com. Clique aqui para navegar pela venda: https://loja.com. Click migly.in/o/xXx p/ sair.
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </div>
                  </v-img>
                </center>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </section>
   </v-slide-y-transition>
   <v-container class="mt-5">
    <div class="primary--text display-title-1 mt-5" >{{$t('analytcsTitle')}}</div>
    <div class="text-subtitle-1">
      {{$t('analytcsSubTitle')}}
    </div>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-img src="/images/010.png" class="mt-5 ml-5"/>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <div class="text-description-1 verticle-center text-right">
          {{$t('analytcsDescription')}}
        </div>
      </v-col>
    </v-row>
   </v-container>
   <v-container class="mt-5" id="sec-2">
    <v-card elevation="0" class="card-blue mt-4 pa-4" outlined>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="7" class="verticle-center">
          <v-fade-transition>
            <div v-show="isShowGroup2">
             <v-card-actions class="display-title-1 header-title primary--text" >
               <h3><b>{{$t('uniqueLink').toLowerCase()}}</b></h3>
             </v-card-actions>
             <v-card-actions class="text-description-1">
               <v-spacer/>
               O meu migly conecta todas suas páginas, post nas redes sociais, videos no youtube, qualquer conteúdo ou projeto que você tenha online em uma única página biográfica.
               Além de um link curto você terá um QRCode associado a sua bio. Imprima e facilite o acesso ao seus projetos.
             </v-card-actions>
             <v-card-actions>
               <v-spacer/>
               <BtnLogin :screenData="screenData" class="link mr-2"/>
               <v-btn dark color="primary" @click="$router.push('/painel/links/page-bio')" v-if="screenData && screenData.auth">
                 {{$t('createBio')}}
               </v-btn>
               <v-spacer/>
             </v-card-actions>
            </div>
          </v-fade-transition>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="5">
          <v-slide-x-transition>
           <v-carousel cycle hide-delimiter-background show-arrows-on-hover v-if="isShowGroup2">
             <v-carousel-item>
               <center><v-img src="images/003.png" width="300" contain/></center>
             </v-carousel-item>
             <v-carousel-item>
               <!-- <center>
                 <QRCodeMigly value="http://migly.in" class="ml-1 mr-1 mt-1 mb-1"/>
               </center> -->
               <center><v-img src="images/008.png" width="300" contain/></center>
             </v-carousel-item>
             <v-carousel-item v-for="index in 4" :key="index">
               <center>
                 <v-img :src="`images/00${index + 3}.PNG`" width="300" contain/>
               </center>
             </v-carousel-item>
           </v-carousel>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </v-card>
   </v-container>
   <section id="sec-2" v-if="false">
    <GenerateLinkWhatsApp :screenData="screenData"/>
   </section>
   <v-container>
    <v-card-actions>
      <v-spacer/>
        <v-slide-x-transition>
          <div v-show="isShowGroup1">
            <carousel :autoplay="true" :perPage="3" v-show="false">
              <slide v-for="item in $t('smsCarrosel')" :key="item.title">
                <v-card width="170" class="ma-2" color="blue" style="padding: 1px;">
                  <v-card elevation="0">
                    <v-card-title><v-icon x-large color="blue">{{ item.icon }}</v-icon></v-card-title>
                    <v-card-subtitle>{{ item.title }}</v-card-subtitle>
                  </v-card>
                </v-card>
              </slide>
            </carousel>
          </div>
        </v-slide-x-transition>
        <v-spacer/>
    </v-card-actions>
   </v-container>
 </div>
</template>

<script>
import UrlShortener from "./fragments/UrlShortener.vue";
import GenerateLinkWhatsApp from "./fragments/GenerateLinkWhatsApp.vue";
import BtnLogin from "./fragments/BtnLogin.vue";
import SubscriptionAndPlans from "./fragments/SubscriptionAndPlans.vue";
import InfoproductView from './fragments/InfoproductView.vue';

// import QRCodeMigly from "./fragments/QRCodeMigly.vue";
  export default {
    name: 'IndexPage',
    props: ['screenData'],
    components: {
    UrlShortener,
    GenerateLinkWhatsApp,
    BtnLogin,
    SubscriptionAndPlans,
    InfoproductView,
},
    data: () => ({
      isShowGroup1: false,
      isShowGroup2: false,
      infoProductEditData: {
        infoproduct: null
      },
    }),
    mounted: function() {
      setTimeout(() => {
        this.isShowGroup1 = true
        setTimeout(() => {
          this.isShowGroup2 = true
          setTimeout(() => {
            var container = this.$el.querySelector(window.location.hash);
            if(container)
              container.scrollIntoView({behavior: 'smooth'});
          }, 500);
        }, 500);
      }, 500);

      this.infoProductEditData.infoproduct = 
          {
            resource: {src: ''},
            brandImage: {src: '/images/logo/logo-white-07.svg'},
            title: 'Guia para Criar uma Página de Coleta de Leads Eficiente',
            subtitle: 'DOWNLOAD GRATUITO',
            shortDescription: 'Um e-book com dicas práticas para criação de páginas para coleta de leeds.',
            productImage: {src: '/images/logo/logo-white-07.svg'},
            action: 'DOWNLOAD',
            itensTitle: 'Quais assuntos vou encontrar no e-book?',
            itens: [
              {id: 1, value: 'Importância das Páginas de Coleta de Leads', deleted: false},
              {id: 1, value: 'Ofereça uma oferta de valor', deleted: false},
              {id: 1, value: 'Otimize para dispositivos móveis', deleted: false},
              {id: 1, value: 'Teste e otimize continuamente', deleted: false},
              {id: 1, value: 'Desenvolva um formulário de captura', deleted: false},
              {id: 1, value: 'Projete uma chamada para ação (CTA) clara', deleted: false},
              {id: 1, value: 'Adicione elementos de confiança', deleted: false},
              {id: 1, value: 'Acompanhe e analise os resultados', deleted: false}
            ],
            description: `O eBook "Guia Prático para Criar Páginas de Coleta de Leads Eficazes" apresenta estratégias fundamentais para maximizar o potencial das páginas de coleta de leads no contexto do marketing digital. Desde a compreensão da importância dessas páginas até a definição dos elementos essenciais e das melhores práticas de design, o eBook explora também estratégias de geração de tráfego e técnicas para mensuração e otimização de resultados. Ao final, os leitores encontrarão um guia completo para criar e otimizar páginas de coleta de leads que impulsionem o crescimento do negócio de forma eficaz e sustentável.`,
            style: {
              headerColor: '#1565C0FF', 
              titleColor: '#FFFFFFFF', 
              subtitleColor: '#FFFFFFFF', 
              shortDescriptionColor: '#FFFFFFFF',
              btnActionColor: '#FFFFFFFF',
              btnActionTextColor: '#1565C0FF',
              itensTitleColor: '#1565C0FF',
              itensColor: '#1565C0FF',
              descriptionColor: '#1565C0FF',
              descriptionBackgroundColor: '#f3f3f3',
              formColor: '#1565C0FF',
              footerBackgroundColor: '#1565C0FF',
              footerTextColor: '#fff'
            },
            formFields: [
                {id:1, name: 'nome', type: 'TEXT_FIELD', options: []},
                {id:1, name: 'profissão', type: '', options: []}
            ],
            footer: {
              brandImage: {src: '/images/logo/logo-white-07.svg'},
              text: `Crie sua página de coleta de leeds em migly.in`,
              links: [
                {href: 'http://migly.in', name: 'Facebook'},
                {href: 'http://migly.in', name: 'Instagram'}
              ]
            },
            user: {subscriptionPlan: {id: 3}},
            onlineAddress: {
              code: '',
              id: null
            }
          }

      if(!this.isShow()){
        this.$router.push('/painel/links/shortened')
      }
    },
    computed: {

    },
    methods: {
      redirectTo: function(route) {
        localStorage.setItem('route' , route)
        window.location = `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
      isShow: function() {
        return !localStorage.authorization
      }
    },
  }
</script>
<style scoped>
.header-title {
  /* font-weight: lighter; */
  color: #1A237E;
  /* font-weight: 800!important; */
  margin-bottom: 0.66667rem;
}

.text-description-shorten-link-1 {
  color: var(--migly-neutral-dark, #7E8EA6);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img-description {
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #1A237E;
}
.card-blue {
  border-radius: 2em;
}

.title-desc-sms{
  color: var(--migly-primary-normal, #0079F1);
  /* H2 - md */
  font-family: Montserrat;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-subtitle-1{
  color: var(--migly-neutral-dark, #7E8EA6);
  /* Paragraph md */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.text-description-1{
  color: var(--migly-base-normal, #252F3F);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.625rem; /* 175% */
}

.url-shorten-bonus{
  border-radius: 1.07469rem;
  border: 1.433px solid  #0079F1 !important;
  background: #FFF;
}
.business-link-1{
  border-radius: 1.5rem;
  border: 4px solid var(--Migly-Neutral-Dark, #7E8EA6);
}

.business-link-text-1{
  color: var(--Migly-Primary-Normal, #0079F1);
  font-family: Montserrat;
  font-size: 1.16231rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02325rem;
}

.business-link-text-2{
  color: var(--Migly-Primary-Normal, #0079F1);
  font-family: "Passion One";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (min-width: 960px) {

}
@media (min-width: 1264px) {
  .title-shorten-links-benefts {
    margin-left: 4em;
    margin-right: 4em;
  }
}
@media (min-width: 1904px) {
  .title-shorten-links-benefts {
    margin-left: 3em;
    margin-right: 3em;
  }
}
</style>