<template>
  <v-menu 
    offset-y 
    left
    v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-badge overlap bordered left color="primary" :value="(linksErrors  ? 1 : 0) + 1" :content="(linksErrors  ? 1 : 0) + 1">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          icon
          class="mr-4"
          small
  
        >
          <v-icon v-if="menu">mdi-bell</v-icon>
          <v-icon v-else>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card elevation="0" class="rounded-lg" width="300">
      <v-card-actions>
        <v-btn icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <b>{{ $t('notifications') }}</b>
        <v-spacer/>
        <v-btn icon color="#7E8EA6" @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider class="mr-4 ml-4"/>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="white-space: unset;" class="primary--text">
              <v-icon color="primary">mdi-circle-small</v-icon>
              <b>{{ $t('newsMigly').title }}</b>
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: unset;" class="description--text">{{$t('newsMigly').subtitle}}</v-list-item-subtitle>
            <v-list-item-subtitle style="white-space: unset;">
              <v-card-actions class="ma-0 pa-0">
                <v-spacer/>
                <v-btn text @click="$router.push('/painel/whats-new')" class="rounded-lg text-none">
                  {{$t('learnMore')}}
                </v-btn>
              </v-card-actions>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mr-4 ml-4"/>
        <v-card-actions v-if="loading">
          <v-spacer/>
          <v-progress-circular indeterminate/>
          <v-spacer/>
        </v-card-actions>
        <v-list-item v-else-if="linksErrors ">
          <v-list-item-content>
            <v-list-item-title style="white-space: unset;" class="primary--text">
              <v-icon color="primary">mdi-circle-small</v-icon>
              <b>{{ $t('linksErrorTitle') }}</b>
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: unset;" class="description--text">{{$t('linksErrorVerify').replace('{0}', page.totalElements)}}</v-list-item-subtitle>
            <v-list-item-subtitle style="white-space: unset;">
              <v-card-actions class="ma-0 pa-0">
                <v-spacer/>
                <v-btn text @click="verifyLinks" class="rounded-lg text-none">
                  {{$t('toCheck')}}
                </v-btn>
              </v-card-actions>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="false">
          <v-list-item-content>
            <v-list-item-subtitle style="white-space: unset;" class="description--text">{{$t('withoutNotifications')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>

</template>
<script>
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses'
  export default {
    name: 'Notifications',
    props: ['screenData'],
    components: {
    },
    data: () => ({
      loading: false,
      menu: false,
      linksErrors: false,
      page: {
        totalElements: 0
      }
    }),
    mounted: function() {
      this.loading = true
      apiV1OnlineAddresses.page({page: 0, size: 1, error: true}).then(result => {
        if(!result.data.empty) {
          this.linksErrors = true
        }else {
          this.linksErrors = false
        }
        this.page = result.data
        this.loading = false
      })
    },
    computed: {

    },
    methods: {
      verifyLinks: function() {
        var query = this.$route.query
        query.error = true
        query.page = 0
        query.typeIn = null
        this.$router.push('/painel/links/shortened?page=0&errorIn=1')
      },
    },
  }
</script>
<style scoped>
</style>