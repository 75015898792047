<template>
  <v-app>
    <div class="full-height full-width">
      <v-fade-transition>
        <router-view/>
      </v-fade-transition>
    </div>
    <v-snackbar
      top
      right
      v-model="snackbar.show"
      close-delay="2000"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay.loading">
      <v-progress-circular indeterminate color="primary"/>
    </v-overlay> 
    <Cookies />
  </v-app>
</template>

<script>

import http from './service/axios';
import Cookies from './components/pages/fragments/Cookies.vue';

export default {
  name: 'App',
  components: {
    Cookies
  },

  data: () => ({
    snackbar: {show: false, color: 'error', text: ''},
    overlay: {loading: false},
  }),

  watch: {
  },
  mounted: function() {
   
    // if (location.host != '' && location.protocol !== 'https:' && process.env.VUE_APP_ENVIROMENT == 'PRD') {
    //   location.replace(`https:${location.href.substring(location.protocol.length)}`);
    // }
    this.$commons.updateSnackbar = (snackbar) => {
      this.snackbar = snackbar
    }
    this.$commons.updateOverlayLoading = (loading) => {
      this.overlay.loading = loading
    }
    http.interceptors.response.use(
      response => response,
      error => {
        if(error.response.data && error.response.data.messages)
          error.response.data.messages.forEach((element,index) => {
            setTimeout(() => {
              this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t(element.message)})
            }, index * 1000);
          });
        // if (error.response.status === 403) {
        //   localStorage.clear()
        // }
        return Promise.reject(error);
      }
    )
  },
  methods: {

  }

};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
$font-family:'Montserrat';

.v-application {
  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
  height: 100%;
  max-height: 100%;
}
* {
  font-family: $font-family, sans-serif !important;
  word-break: normal !important;
}
.display-title-1 {
    font-size: 3.9rem !important;
    font-family: $font-family, sans-serif !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;
}

.display-title-2 {
  text-align: center !important;
  /* H2 - md */
  font-size: 2.7rem!important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.v-application .display-title-3 {
    font-size: 3.75rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
}

html {
  scroll-behavior: smooth;
}
.full-height {
  height: 100vh;
  max-height: 100vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: clip;
}
.full-height-90 {
  height: 93vh;
  max-height: 100%;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: clip;
}
.full-height-80 {
  height: 83vh;
  max-height: 100%;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: clip;
}
.full-width {
  width: 100vw; 
  max-width: 100vw;
}
.m-0 {
  margin: 0px;
}
.p-0 {
  padding: 0px;
}
::-webkit-scrollbar {
    width: 1px;
    height: 2em
}
::-webkit-scrollbar-button {
    background: transparent
}
::-webkit-scrollbar-track-piece {
    background: transparent
}
::-webkit-scrollbar-thumb {
  background: #eee
}
.card-circle {
  border-radius: 50% !important;
}

.bg-main {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.badge-info .v-badge__badge {
  height: none !important;
  padding: 0px !important;
  z-index: 9;
  white-space: normal !important;
}

.avatar-breed {
  border: 1px dotted white !important;
}

.avatar-craft {
  border: 1px dotted grey !important;
}

.verticle-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}
.horizontaly-center {
  margin: auto;
}

.link{
  text-decoration: none;
  font-weight: bold;
}

.bg-card{
  background: rgb(255, 255, 255, 0.1);
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur(10px);
}

.pointer {
  cursor: pointer;
}

.text-line-through{
  text-decoration: line-through;
}

.rounded-card{
    border-radius:50% !important;
}

// .v-navigation-drawer--fixed{
//   z-index: 9 !important;
// }

// .v-navigation-drawer--fixed{
//   z-index: 6 !important;
// }

.v-toolbar.v-toolbar--absolute{
  z-index: auto !important;
}

.lowercase input{
  text-transform: lowercase
}


.free-plan-obfuscate {
  background: rgb(255, 255, 255, 0.1) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -webkit-filter: blur(5px) !important;
}

.card-corner-radius{
  border-radius: 15px !important;
}

.append-outer-no-margin-top .v-input__append-outer {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.subtitle-deafault{
  color: #7E8EA6;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.all-text {
  white-space: pre-line !important
}
.description--text{
  color: #7E8EA6 !important;
}

.text-end-input input {
  text-align: end !important
}

@media (min-width: 960px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 1140px !important;
  }
}
@media (min-width: 1904px) {
  .container {
    max-width: 1140px !important;
  }
}
</style>