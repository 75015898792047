<template>
  <v-main class="grey lighten-3">
    <v-app-bar
      id="appBar"
      elevation="0"
      color="white"
      elevate-on-scroll
      scroll-target=".full-height-80"
      :extension-height="''"
    >
      <v-container id="appBar-content-1">
        <v-card-actions>
          <a href="/painel/links/shortened" class="link">
            <v-img src="/images/logo/logo-04.png" alt="migly" width="100"/> 
          </a>
          <v-spacer/>
          <Search :screenData="screenData" v-if="!['xs'].includes($vuetify.breakpoint.name)"/>
          <v-spacer/>
          
          <v-chip color="transparent" v-if="!['xs'].includes($vuetify.breakpoint.name)" @click="$router.push('/painel/links/shortened')">
            <v-icon color="blue" class="mr-1">mdi-link</v-icon>
            <div>
              {{$t('links')}}
            </div>
          </v-chip>
          <v-chip color="transparent" v-if="!['xs'].includes($vuetify.breakpoint.name)" @click="$router.push('/painel/api-reference')">
            <v-icon color="green" class="mr-1">mdi-api</v-icon>
            <div>
              {{$t('apiDoc')}}
            </div>
          </v-chip>

          <v-chip color="transparent" v-if="!['xs'].includes($vuetify.breakpoint.name) && false" @click="$router.push('/painel/nfoproduct')">
            <v-icon color="blue" class="mr-1">mdi-weather-cloudy</v-icon>
            <div>
              {{$t('infoProduct')}}
            </div>
          </v-chip>
          <v-chip color="transparent" v-if="!['xs'].includes($vuetify.breakpoint.name) && false" @click="$router.push('/painel/sms')">
            <v-icon color="blue" class="mr-1">mdi-message-outline</v-icon>
            <div>
              {{$t('sms')}}
            </div>
          </v-chip>
          <v-chip color="transparent" v-if="screenData.auth && screenData.auth.user && !screenData.auth.user.userHasSubscriptionPlans.filter(v => !v.subscriptionPlan.free)[0]" @click="planData.dialogPlansAndPrices = true">
            <v-icon color="blue" class="mr-1">mdi-star</v-icon>
            <div v-if="!['xs'].includes($vuetify.breakpoint.name)">
              {{$t('upgradeToPlus')}}
            </div>
          </v-chip>
          <v-chip color="transparent" class="pointer" disabled v-show="false">
            <v-icon color="blue" class="mr-1">mdi-help-circle-outline</v-icon>
            <div v-if="!['xs'].includes($vuetify.breakpoint.name)">
              {{$t('help')}}
            </div>
          </v-chip>
          <Help />
          <Notifications/>
          <BtnProfile :screenData="screenData" />
        </v-card-actions>
      </v-container>
      <template v-slot:extension>
        <v-row no-gutters style="margin-left: -2em; margin-right: -2em;" id="appBar-content-2">
          <v-col cols="12" v-if="false">
            <v-card color="#0079F1" class="w-100 " elevation="0" dark @click="$router.push('/painel/nfoproduct')" >
              <v-card-actions>
                <v-spacer/>
                <v-icon>mdi-storefront</v-icon>
                <b class="ml-1">{{ $t('sellInfoproducts') }}</b>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col v-if="['xs'].includes($vuetify.breakpoint.name)">
            <v-container class="pl-5 pr-5">
              <Search :screenData="screenData" />
            </v-container>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <v-card :height="getAppBarHeight() + 'px'" elevation="0" color="transparent" style="z-index: -1;" v-if="false"/>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto box-content"
      :style="`position: fixed; width: 100%; height: calc(100% - ${!['xs'].includes($vuetify.breakpoint.name) ? getAppBarHeight() : 0}px);overflow: hidden`"
    >
      <v-container>
        <v-row v-if="screenData.auth.user.userHasSubscriptionPlans[0]">
          <v-col cols="12" sm="2" md="2" v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => !v.subscriptionPlan.free)[0]">
            <v-card class="card-corner-radius" v-if="['xs'].includes($vuetify.breakpoint.name)" @click="planData.dialogPlansAndPrices = true" color="primary">
              <v-card-actions class="verticle-center">
                <v-spacer/>
                <v-btn color="white" dark text x-small @click="planData.dialogPlansAndPrices = true">
                  <v-icon>mdi-crown</v-icon>
                </v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-card>
            <v-card class="card-corner-radius" v-else height="100%" style="border: 1px dotted grey;" color="primary" @click="planData.dialogPlansAndPrices = true">
              <v-card-actions class="verticle-center">
                <v-spacer/>
                <v-btn color="white" dark text x-small @click="planData.dialogPlansAndPrices = true">
                  <v-icon>mdi-crown</v-icon>
                </v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-col>
          
          <v-col 
            cols="12" 
            :sm="(!screenData.auth.user.userHasSubscriptionPlans.filter(v => !v.subscriptionPlan.free)[0]) ? 10 : 12">
            <router-view :screenData="screenData" ></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800" :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="planData.dialogPlansAndPrices = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog  v-model="planData.dialogSubscriptionPlan" width="550" :persistent="loadSubscriptionPlan">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogSubscriptionPlan = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <template v-if="!assign">
          <v-card-text>
            <v-skeleton-loader type="card-heading"/>
            <v-skeleton-loader type="list-item-avatar" v-for="i in 3" :key="`loading-subscriotins-${i}`"/>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-title>
            {{$t(`youAssignPlan`)}} {{$t(`plan_${assign.subscriptionPlan.name}`)}}
          </v-card-title>
          <v-card-text>
            <template v-if="loadSubscriptionPlan">
              <v-skeleton-loader type="list-item-avatar" v-for="i in 3" :key="`loading-subscriotins-${i}`"/>
            </template>
            <template v-else>
              <div v-for="subscription, index in assign.subscriptions" :key="`assign-card-${index}`" class="mb-2">
                <v-card :color="subscription.status == 'active' ? `green` : subscription.status == 'canceled' ? `grey` : subscription.status == 'future' ? 'primary' : 'red'" dark>
                  <v-card-actions>
                    <v-icon small color="white" class="mr-1">mdi-circle-slice-8</v-icon>
                    <v-chip color="white" light small>
                      {{$t(`plan_${subscription.subscriptionPlan.name}`)}} 
                    </v-chip>
                    <v-spacer/>
                    <v-chip small :color="subscription.status == 'active' ? 'primary' : 'dark'" dark >
                      {{$t(subscription.status)}}
                    </v-chip>
                  </v-card-actions>
                  <div v-if="subscription.status == 'active' || subscription.status == 'future'">
                    <v-card-text >
                      <v-chip-group column>
                        <v-chip small color="white" light>
                           <v-icon small class="mr-1">mdi-calendar</v-icon>
                           <span v-if="subscription.status == 'future'">
                            {{$t(`start_in`)}}
                           </span>
                           <span v-else>
                            {{$t(`start_at`)}}
                           </span>
                           <span class="ml-1">
                             {{subscription.start_at}}
                           </span>
                        </v-chip>
                        <v-chip color="white" light small v-if="subscription.status == 'active'">
                           <v-icon small class="mr-1">mdi-calendar</v-icon>
                          {{$t(`next_billing_at`)}} {{subscription.next_billing_at}}
                        </v-chip>
                        <v-chip small color="white" light>
                           <v-icon small class="mr-1">mdi-update</v-icon>
                          {{$t(`interval`)}} {{$t(subscription.interval)}}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="subscription.current_cycle">
                      <v-chip-group>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_cycle`)}} {{subscription.current_cycle.cycle}}
                        </v-chip>
                        <v-chip small :color="subscription.current_cycle.status == 'billed' ? 'light' : 'dark'" dark >
                          {{$t(subscription.current_cycle.status)}}
                        </v-chip>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_billing_at`)}} {{subscription.current_cycle.billing_at}}
                        </v-chip>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_start_at`)}} {{subscription.current_cycle.start_at}} {{$t(`endAt`)}} {{subscription.current_cycle.end_at}}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </div>
                </v-card>
              </div>
            </template>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn color="red" text x-small :disabled="!assign.subscriptions.filter(s => s.status != 'canceled').length" @click="dialogCancel = true">
              {{$t('cancelSubscrible')}}
            </v-btn>
            <v-spacer/>
            <v-btn color="primary" dark text x-small @click="planData.dialogPlansAndPrices = true">
              {{$t('plansAndPrices')}}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancel" :persistent="loadCancelSubscriptionPlan" width="350">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn icon color="red" @click="dialogCancel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-title>
          {{$t('titleCancel')}}
        </v-card-title>
        <v-card-text>
          {{$t('textCancel')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="red" @click="cancelSubscrible" :loading="loadCancelSubscriptionPlan">
            {{$t('confirmCancelSubscrible')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfig" width="800" persistent>
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
          <v-btn @click="dialogConfig = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <Config :screenData="screenData"/>
      </v-card>
    </v-dialog>
    <UserSignTermsAndPrivacyPolicy />
  </v-main>
</template>

<script>
import { apiV1Subscription } from "../../../service/resources/api-v1-subscription";
import BtnProfile from "../fragments/BtnProfile.vue";
import SubscriptionAndPlans from "../fragments/SubscriptionAndPlans.vue";
import Config from "../fragments/Config.vue";
import IconInfoSubscriblePlan from "../fragments/IconInfoSubscriblePlan.vue";
import Search from "../fragments/Search.vue";
import UserSignTermsAndPrivacyPolicy from "../fragments/UserSignTermsAndPrivacyPolicy.vue";
import Notifications from '../fragments/Notifications.vue'
import Help from '../fragments/Help.vue'

  export default {
    name: 'Painel',
    components: {
    BtnProfile,
    SubscriptionAndPlans,
    Config,
    IconInfoSubscriblePlan,
    Search,
    UserSignTermsAndPrivacyPolicy,
    Notifications,
    Help
},
    data: () => ({
      dialogConfig: false,
      dialogCancel: false,
      loadSubscriptionPlan: false,
      loadCancelSubscriptionPlan: false,
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false
      },
      drawer: false,
      miniVariante: true,
      expandOnHover: true,
      assign: null,
      screenData: {
        address: null,
        auth: {
          provider: {
            
          },
          user: {
            userHasSubscriptionPlans: []
          }
        },
        page: {
          content: []
        },
        dashboardMini: false,
        dashboard: false
      }
    }),
    computed: {

    },
    mounted: function() {
      window.addEventListener("resize", this.$forceUpdate());
      //gtag('event', 'conversion', {'send_to': 'AW-10837641993/I4MsCNi9g5EZEImW5a8o'});
    },
    methods: {
      getAppBarHeight: function() {
        if(document.getElementById('appBar'))
          return document.getElementById('appBar-content-1').getBoundingClientRect().height + document.getElementById('appBar-content-2').getBoundingClientRect().height -25
        else
          return 0
      },
      closeDrawer: function() {
        let self = this
        setTimeout(() => {
          self.miniVariante = true
          self.expandOnHover = false
        }, 2000);
      },
      logout: function() {
        localStorage.clear()
        this.screenData.auth = null
        this.$router.push('/loading')
      },
      getSubscriptionPlan: function() {
        this.planData.dialogSubscriptionPlan = true
        this.drawer = false
        this.loadSubscriptionPlan = true
        this.assign = null
        apiV1Subscription.get().then(result => {
          this.drawer = false
          this.loadSubscriptionPlan = false
          this.assign = result.data
        }).catch(() => {
          this.loadSubscriptionPlan = false
        })
      },
      cancelSubscrible: function() {
        this.loadCancelSubscriptionPlan = true
        apiV1Subscription.delete().then(() => {
          this.loadSubscriptionPlan = true
          this.dialogCancel = false
          apiV1Subscription.get().then(result => {
            this.assign = result.data
            this.loadCancelSubscriptionPlan = false
            this.loadSubscriptionPlan = false
          })
        }).catch(() => {
          this.loadCancelSubscriptionPlan = false
        })
      }
    },
  }
</script>
<style>
.card-circle{
  border-radius: 50% !important;
}

.box-content {
  background-color: #E7EBF2 !important;
}
.v-toolbar__extension{
  background-color: white;
}
</style>