<template>
  <div>
    <v-fade-transition leave-absolute>
      <v-skeleton-loader v-if="!bio" type="card-avatar, article, list-item-three-line, actions"/>
      <div v-else>
        <v-img :src="bio.cover && bio.cover.src ? bio.cover.src : '/black.jpeg'" min-height="100%" min-width="100%">
          <div absolute class="gradient" width="100%" height="100%"/>
          <v-app-bar color="transparent" elevation="0" v-if="bio.onlineAddress.code" class="pa-1">
            <v-btn rounded color="white" small>
              <ShareOptions :onlineAddress="bio.onlineAddress"/>
            </v-btn>
          </v-app-bar>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-actions class="pt-5 pb-0 mt-5 mb-0">
                <v-spacer/>
                <v-card class="rounded-card">
                  <v-card-text class="pt-1 pl-1 pr-1 pb-1">
                    <v-avatar size="140">
                      <v-img :src="bio.picture && bio.picture.src ? bio.picture.src : null"/>
                    </v-avatar>
                  </v-card-text>
                </v-card>
                <v-spacer/>
              </v-card-actions>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col>
                  <v-card color="transparent" elevation="0">
                    <v-card-title class="pr-0 pl-0 bio-name">
                      <v-spacer/>
                      {{bio.name}}
                      <v-spacer/>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-col v-for="(biographyHasOnlineAddress, index) in bio.biographyHasOnlineAddresses.filter(i => !i.deleted && i.onlyIcon)" :key="`link-icon-only-${index}`" cols="3">
              <center>
                <v-btn rounded @click="goTo(biographyHasOnlineAddress.onlineAddress)" light color="white">
                  <v-icon v-if="isYoutube(biographyHasOnlineAddress.onlineAddress)" color="red">mdi-youtube</v-icon>
                  <v-icon v-else-if="isFacebook(biographyHasOnlineAddress.onlineAddress)" color="primary">mdi-facebook</v-icon>
                  <v-icon v-else-if="isInstagram(biographyHasOnlineAddress.onlineAddress)" color="red --dark">mdi-instagram</v-icon>
                  <v-icon v-else-if="isLinkedin(biographyHasOnlineAddress.onlineAddress)" color="blue">mdi-linkedin</v-icon>
                  <v-icon v-else-if="isWhatsapp(biographyHasOnlineAddress.onlineAddress)" color="green">mdi-whatsapp</v-icon>
                  <v-icon v-else-if="isEmail(biographyHasOnlineAddress.onlineAddress)">mdi-email</v-icon>
                  <v-icon v-else-if="isEducation(biographyHasOnlineAddress.onlineAddress)">mdi-school</v-icon>
                  <v-icon v-else-if="isTwitter(biographyHasOnlineAddress.onlineAddress)" color="blue --dark">mdi-twitter</v-icon>
                  <v-icon v-else>mdi-web</v-icon>
                </v-btn>
              </center>
            </v-col>
            <v-spacer/>
          </v-row>
          <v-card-actions/>
          <v-card-text>
            <v-card elevation="0" class="mt-2 mb-2 rounded-lg" color="transparent">
              <v-card class="box-description rounded-lg"/>
              <v-card color="transparent" elevation="0" class="rounded-lg">
                <v-card-subtitle class="bio-description">
                  {{bio.description}}
                </v-card-subtitle>
              </v-card>
            </v-card>
          </v-card-text>
          <v-row no-gutters>
            <v-col v-for="(biographyHasOnlineAddress, index) in bio.biographyHasOnlineAddresses.filter(i => !i.deleted && !i.onlyIcon)" :key="index" cols="12 ">
              <v-card class="ml-3 mr-3 mb-2 mt-2 rounded-lg card-link" @click="goTo(biographyHasOnlineAddress.onlineAddress)" dark color="primary">
                <v-card-actions>
                  <v-spacer/>
                  <v-icon v-if="isYoutube(biographyHasOnlineAddress.onlineAddress)">mdi-youtube</v-icon>
                  <v-icon v-else-if="isFacebook(biographyHasOnlineAddress.onlineAddress)">mdi-facebook</v-icon>
                  <v-icon v-else-if="isInstagram(biographyHasOnlineAddress.onlineAddress)">mdi-instagram</v-icon>
                  <v-icon v-else-if="isLinkedin(biographyHasOnlineAddress.onlineAddress)">mdi-linkedin</v-icon>
                  <v-icon v-else-if="isWhatsapp(biographyHasOnlineAddress.onlineAddress)">mdi-whatsapp</v-icon>
                  <v-icon v-else-if="isEmail(biographyHasOnlineAddress.onlineAddress)">mdi-email</v-icon>
                  <v-icon v-else-if="isEducation(biographyHasOnlineAddress.onlineAddress)">mdi-school</v-icon>
                  <v-icon v-else-if="isTwitter(biographyHasOnlineAddress.onlineAddress)">mdi-twitter</v-icon>
                  <v-icon v-else>mdi-web</v-icon>
                  <div class="ml-1 mr-1"/>
                  {{biographyHasOnlineAddress.onlineAddress.tags}}
                  <v-spacer/>
                </v-card-actions>
                <v-card-text class="pl-0 pb-0 pr-0 mb-0 pt-0" v-if="isYoutube(biographyHasOnlineAddress.onlineAddress) && isYoutubePreview(biographyHasOnlineAddress.onlineAddress) ">
                  <iframe width="100%" :src="biographyHasOnlineAddress.onlineAddress.url.replace('/watch?v=', '/embed/')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-footer height="350" color="transparent"></v-footer>
          <v-footer color="transparent" absolute class="pa-0" v-if="!onlySmartphone">
            <v-spacer/>
            <v-card elevation="0" flat color="transparent">
              <v-card-actions class="white--text">
                <v-spacer/>
                <a href="/" target="_blank">
                  <v-btn rounded class="text-none" elevation="5">
                    <img src="/images/logo/logo-02.png" width="25" height="25" class="mr-2"/> 
                    {{ $t('createYoutMiglyPage') }}
                  </v-btn>
                </a>
                <v-spacer/>
              </v-card-actions>
            </v-card>
            <v-spacer/>
          </v-footer>
        </v-img>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import ShareOptions from './ShareOptions.vue';
  export default {
    name: 'BiographySmartphoneView',
    props: ['bio', 'onlySmartphone'],
    components: {
    ShareOptions
},
    data: () => ({
      loading: true,
      host: null

    }),
    mounted: function() {
      setTimeout(() => {
        this.loading = false
      }, 1000);
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
    },
    computed: {
      
      
      },
    methods: {
      isFacebook: function(address) {
        return address.url.includes('facebook')
      },
      isYoutube: function(address) {
        return address.url.includes('youtube') || address.url.includes('youtu.be')
      },
      isInstagram: function(address) {
        return address.url.includes('instagram')
      },
      isLinkedin: function(address) {
        return address.url.includes('linkedin')
      },
      isWhatsapp: function(address) {
        return address.url.includes('whatsapp')
      },
      isEmail: function(address) {
        return address.url.includes('@')
      },
      isEducation: function(address) {
        return address.url.includes('lattes') || address.url.includes('buscatextual.cnpq')
      },
      isTwitter: function(address) {
        return address.url.includes('twitter')
      },
      isYoutubePreview: function(address) {
        return address.url.includes('watch')
      },
      goTo(address) {
        if(address.code) {
          let route = this.$router.resolve({ path: `/${address.code}` });
          window.open(route.href);
        }else if(address.url) {
          window.open(address.url); 
        }
      },
    },
  }
</script>
<style>
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.cover-content {
  /* background-image: linear-gradient(red, yellow); */
  background-image: linear-gradient(rgba(255,0,0,0), rgba(0,0,0,1));
}
.gradient{
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(255,0,0,0), rgba(0,0,0,1));
}

.box-description{
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 25px;
}
.bio-name {
  font-size: 2em;
  font-style: normal;
  color: white !important;
  /* -webkit-text-stroke: 0.2px #000; */
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}

.bio-description{
  font-style: normal;
  font-size: 1.3em;
  color: white !important;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  /* text-align: center; */
}

.card-rouded{
  border-radius: 25px 25px 25px 25px!important;
}

.card-link{
  font-size: 1.2em;
  padding: 0.2em;
}
</style>