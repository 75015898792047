<template>
  <v-row no-gutters v-if="screenData.auth" style="overflow: auto; max-height: 100vh;">
    <v-col cols="12" sm="7">
      <v-card-text class="pt-0 mt-0">
        <v-card v-if="loading" elevation="0">
          <v-card-text>
            <v-skeleton-loader type="card-heading" />
            <v-skeleton-loader type="list-item-two-line" />
            <v-skeleton-loader type="article, actions"/>
          </v-card-text>
        </v-card>
        <v-card v-else elevation="0" style="overflow: auto; max-height: 80vh;">
          <v-card-subtitle><b>{{ $t('screenLoading') }}</b></v-card-subtitle>
          <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('screenLoadingDescription') }}</v-card-subtitle>
          <v-form ref="formConfig" v-model="formConfig" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
            <v-card-subtitle><b>{{$t('logoPicture')}}</b></v-card-subtitle>
            <v-card-actions class="white--text mt-0">
              <v-card class="rounded-card">
                <v-card-text class="pt-1 pl-1 pr-1 pb-1">
                  <v-avatar size="100" >
                    <v-img v-if="config.picture && config.picture.src" :src="config.picture.src"/>
                    <v-icon v-else>mdi-camera</v-icon>
                  </v-avatar>
                </v-card-text>
              </v-card>
              <v-spacer/>
              
              <v-btn rounded color="primary" @click="$refs.imageLogofileInput.$refs.input.click()" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                <v-icon color="white">mdi-upload-circle</v-icon>
              </v-btn>
              <v-file-input
                ref="imageLogofileInput"
                class="mt-0 pt-0 mr-0 pr-0"
                @change="handlePicture"
                hide-input
                prepend-icon="mdi-upload-circle"
                hide-details
                hide-actions
                hide-controls
                accept="image/png, image/jpeg, image/bmp"
                v-show="false"
              />
              <v-btn color="primary" outlined rounded class="ml-2" @click="(config.picture ? config.picture.src = null : config.picture = null),$forceUpdate()" :disabled="!config.picture || !config.picture.src || !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-text-field
                v-if="config.picture"
                v-show="false"
                v-model="config.picture.src"
                outlined
                required
                :rules="[
                  v => !!v
                ]"
                />
            </v-card-actions>
            <v-card-text>
              <v-text-field
                v-model="config.siteName"
                :label="$t('siteName')"
                filled
                rounded
                dense
                required
                maxlength="20"
                counter
                :rules="[
                  v => !!v || $t('required')
                ]"
                hide-details
              />
            </v-card-text>
            <v-card-subtitle><b>{{ $t('loaderTimeTitle') }}</b></v-card-subtitle>
            <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('loaderTimeSubtitle') }}</v-card-subtitle>
            <v-card-text>
              <v-chip-group column v-model="config.loaderTime" @change="progress = 0">
                <v-chip v-for="i in 10" :key="i" :value="i" color="primary" :outlined="i != config.loaderTime" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                  {{ i }}s
                </v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="description--text mt-0 pt-0">{{$t('loaderTime')}} {{config.loaderTime}}s</v-card-subtitle>
            <v-card-subtitle><b>{{ $t('disclosure') }}</b></v-card-subtitle>
            <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('disclosureDescription') }}</v-card-subtitle>
            <v-card-subtitle><b>{{ $t('imageBanner') }}</b></v-card-subtitle>
            <v-card-actions>
              <v-card elevation="0" outlined class="pa-1">
                <v-img :src="config && config.bannerImage && !config.bannerImage.deleted ? config.bannerImage.src : ''" height="100" width="150">
                </v-img>
              </v-card>
              <v-spacer/>
              <v-btn rounded color="primary" @click="$refs.imageBannerfileInput.$refs.input.click()" class="mr-2" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                <v-icon color="white">mdi-upload-circle</v-icon>
              </v-btn>
              <v-file-input
                x-small
                class="ml-3"
                ref="imageBannerfileInput"
                accept="image/*"
                dark
                color="primary"
                hide-input
                hide-details
                hide-actions
                prepend-icon="mdi-file-upload"
                icon
                density="density"
                rounded
                flat
                dense
                @change="handleCrop($event, cropImageBannerData)"
                v-show="false"
              />
              <v-btn v-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src" outlined rounded color="primary" @click="config.bannerImage.id ? config.bannerImage.deleted = true : config.bannerImage = null" :disabled="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <v-text-field
                v-model="config.bannerLink"
                :label="$t('link')"
                filled
                rounded
                dense
                required
                counter
                hide-details
                prepend-inner-icon="mdi-link"
                v-if="(config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src)"
                :rules="[rules.url]"
                />
            </v-card-text>
            <v-card-actions v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO'].includes(v.subscriptionPlan.name))[0]">
              <v-spacer/>
              <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
            </v-card-actions>
            <v-card-actions v-else>
              <v-spacer/>
              <v-btn rounded color="primary" :disabled="!formConfig || screenData.auth.user.bellowShorterProUser" @click="save" :loading="loadingSave">
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
            <v-divider/>
            <v-card-subtitle><b>{{ $t('googleAnalitcs') }}</b></v-card-subtitle>
            <v-card-subtitle class="description--text mt-0 pt-0">
              {{$t('googleAnalitcsDescription')}}
              <a target="_blanc" href="https://support.google.com/analytics/answer/12270356?hl=pt-BR">Google Analytics</a>.
            </v-card-subtitle>
            <v-card-text>
              <v-text-field
                v-model="config.googleAnalyticsId"
                prepend-inner-icon="mdi-google-analytics"
                :label="$t('googleAnalitcsId')"
                filled
                rounded
                dense
                required
                maxlength="12"
                counter
                hide-details
                :disabled="screenData.auth.user.bellowShorterProUser"
                />
            </v-card-text>
            <v-card-actions v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO'].includes(v.subscriptionPlan.name))[0]">
              <v-spacer/>
              <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
            </v-card-actions>
            <v-card-actions v-else>
              <v-spacer/>
              <v-btn rounded color="primary" :disabled="!formConfig" @click="save" :loading="loadingSave">
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
            <v-divider/>
            <v-card-subtitle><b>{{ $t('domainAndSubdomain') }}</b></v-card-subtitle>
            <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('setDomainAndSubdomain') }}</v-card-subtitle>
            <v-card-text>
              <v-text-field
                v-model="config.domain"
                :label="$t('domain')"
                filled
                rounded
                dense
                required
                maxlength="25"
                counter
                disabled
              />
              <v-text-field
                v-model="config.subdomain"
                :label="$t('subdomain')"
                filled
                rounded
                dense
                required
                maxlength="50"
                counter
                class="text-end-input"
                disabled
              >
                <template v-slot:append>
                  <label class="mt-4">.{{ getHost() }}/</label>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('setDomainAndSubdomainSuport') }}</v-card-subtitle>
            <v-card-actions v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
              <v-spacer/>
              <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_BUSINESS')" :planData="planData"/>
            </v-card-actions>
            <v-card-actions v-else>
              <v-spacer/>
              <v-btn rounded color="primary" :disabled="!formConfig || screenData.auth.user.bellowShorterProUser" @click="save" :loading="loadingSave">
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-card-text>
    </v-col>
    <v-col cols="12" sm="5" v-if="!['xs'].includes($vuetify.breakpoint.name)">
      <v-card-text v-if="loading" >
        <v-card-actions class="mb-0 -b-0">
          <v-spacer/>
          <v-skeleton-loader type="avatar" />
          <v-spacer/>
        </v-card-actions>
        <v-skeleton-loader type="list-item" />
      </v-card-text>
      <v-card v-else class="mr-4 mb-4 ml-4" min-height="100%" elevation="0" outlined>
        <v-card-actions>
          <v-chip color="primary">
            {{$t('previewSCreenLoader')}}
          </v-chip>
        </v-card-actions>
        <div class="verticle-center">
          <div class="horizontaly-center">
            <v-card-actions class="mb-0 -b-0">
              <v-spacer/>
              <v-avatar width="150" height="150">
                <v-img v-if="config.picture && config.picture.src" :src="config.picture.src" /> 
                <v-img v-else src="/images/logo/logo-04.png" contain/> 
              </v-avatar>
              <v-spacer/>
            </v-card-actions>
            <v-card-title class="mb-0 -b-0">
              <v-spacer/>
              {{config.siteName}}
              <v-spacer/>
            </v-card-title>
            <v-card-actions>
              <v-spacer/>
              <v-progress-circular x-small :value="progress" width="1" size="22" color="primary"/>
              <v-spacer/>
            </v-card-actions>
            <v-container>
              <v-card elevation="0" color="grey lighten-4"  style="line-break:anywhere">
                <v-card-subtitle>
                  <b class="">{{ $t('youWillBeRedirectedTo') }}</b> 
                  <p><i>{{ getHost() }}/url</i></p>
                </v-card-subtitle>
                <v-divider/>
                  <v-card-actions>
                    <v-spacer/>
                    <v-card small elevation="0">
                        <v-card-subtitle class="pr-2 pl-2 pt-1 pb-1">
                          {{ $t('cencelRedirected') }}
                        </v-card-subtitle>
                        <v-progress-linear x-small :value="progress" height="2" color="primary" class="rounded-lg"/>
                    </v-card>
                  </v-card-actions>
              </v-card>
            </v-container>
          </div>
        </div>
        <v-footer absolute color="transparent" v-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src">
          <v-container>
            <a :href="config.bannerLink" target="_blank">
              <v-card>
                <v-img :src="config.bannerImage.src" height="100"/>
              </v-card>
            </a>
          </v-container>
        </v-footer>
      </v-card>
    </v-col>
    <CropImage :data="cropImageBannerData" :actionSave="setImageBanner" :stencilSize="{width: 200, height: 40}"/>

    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { apiV1Configs } from '../../../service/resources/api-v1-config'
import IconInfoSubscriblePlan from "./IconInfoSubscriblePlan.vue";
import SubscriptionAndPlans from "./SubscriptionAndPlans.vue";
import CropImage from './CropImage.vue';

  export default {
    name: 'Config',
    props: ['screenData'],
    components: {
      CropImage,
      IconInfoSubscriblePlan,
      SubscriptionAndPlans
    },
    data: () => ({
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false,
        planId: null
      },
      tab: 0,
      progress: 0,
      formConfig: true,
      loading: true,
      loadingSave: false,
      config: null,
      cropImageBannerData: {dialog: false, img: null},
      rules: {}
    }),
    mounted: function() {
      this.loading = true
      apiV1Configs.getByAuth().then(result => {
        if(result.data) {
          this.config = result.data
        } else {
          this.config = {}
        }
        this.loading = false
      })

      setInterval(() => {
        this.progress = this.progress + (100 / this.config.loaderTime)
        if(this.$refs.formConfig)
          this.$refs.formConfig.validate()
      }, 1000);

      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }

    },
    computed: {

    },
    methods: {
      getHost: function() {
        //`${window.location.protocol}//${window.location.host.replace('www.', '')}`;
        return `${window.location.host.replace('www.', '')}`
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      setImageBanner: function(image) {
        if(!this.config.bannerImage)
          this.config.bannerImage = {id: null, src: image.src}
        else
          this.config.bannerImage.src = image.src
        this.config.bannerImage.deleted = false
      },
      handlePicture: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.config.picture)
            self.config.picture = {}
          self.config.picture.src = reader.result
          self.$forceUpdate()
        }
      },
      handleCrop: function(event, data){
        var reader = new FileReader()
        reader.readAsDataURL(event)
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('invalid')})
          return
        }
        reader.onload = function () {
          data.img = {src: reader.result}
          data.dialog = true
        }
      },
      save: function() {
        this.loadingSave = true
        apiV1Configs.post(this.config).then(result => {
          this.config = result.data
          this.loadingSave = false
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
        })
      }
    },
  }
</script>
<style scoped>
</style>