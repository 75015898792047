<template>
  <v-menu offset-y right :open-on-hover="false" :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" small icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </template>
    <v-card width="280" class="card-corner-radius overflow-auto" max-height="60vh">
      <v-card-actions>
        <v-btn icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <b>{{ $t('shareOn') }}</b>
        <v-spacer/>
        <v-btn icon color="#7E8EA6" @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-title>
        <v-chip outlined class="primary--text pa-2">
          <input :id="`copy-link-${onlineAddress.id}`"  :value="`${host}/${onlineAddress.code}`" class="primary--text" readonly style="width: 200px;border: 0px;focu"/>

          <v-slide-x-transition>
            <v-icon color="green" class="ml-1" v-if="onlineAddress.copy">
              mdi-check-circle
            </v-icon>
            <v-icon color="primary" class="ml-1" @click="copyLink(onlineAddress)" v-else>
              mdi-content-copy
            </v-icon>
          </v-slide-x-transition>
        </v-chip>
      </v-card-title>
      <v-card-actions v-for="(o, index) in $t('shareOptions')" :key="`share-option-${index}`"  class="pointer pl-5 pr-5" :style="`color: ${o.color}`">
        <v-icon class="mr-1" :color="o.color">{{o.icon}}</v-icon>
        {{o.description}}
        <input :id="`copy-link-shared-on-${onlineAddress.id}-${o.value}`"  :value="`${host}/${onlineAddress.code}?s=${o.value}`" class="primary--text" readonly style="width: 0px;"/>
        <v-spacer/>
        <v-slide-x-transition>
          <v-icon color="green" class="ml-1" v-if="o.copy">
            mdi-check-circle
          </v-icon>
          <v-btn icon @click="copy(onlineAddress, o)" v-else-if="o.value != 'q'" :color="o.color">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-slide-x-transition>
        <v-btn icon @click="share(o)" :color="o.color">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <QRCodeMigly :value="`${host}/${onlineAddress.code}`" :enableControlls="true" width="120" border="0" :data="data" :logo="onlineAddress.user.config && onlineAddress.user.config.picture && !!onlineAddress.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0] ? onlineAddress.user.config.picture.src : null"/>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import QRCodeMigly from './QRCodeMigly.vue';

  export default {
    name: 'ShareOptions',
    props: ['onlineAddress'],
    components: {
    QRCodeMigly
},
    data: () => ({
      host: null,
      menu: false,
      data: {base64: null}
    }),
    mounted: function() {
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
    },
    computed: {

    },
    methods: {
      share(shareOption) {
        if(shareOption.value == 'q') {
          var w = window.open("");
          w.document.write(`<img src="${this.data.base64}"/>`);
        }else
          window.open(`${shareOption.url}${window.location.origin}/${this.onlineAddress.code}?s=${shareOption.value}`); 
      },
      copyLink: function (item) {
        // Seleciona o texto do input
        var texto = document.getElementById(`copy-link-${item.id}`);

        // Seleciona o conteúdo do input
        texto.select();
        texto.setSelectionRange(0, 99999); // Para dispositivos móveis
        // Copia o texto para a área de transferência
        navigator.clipboard.writeText(texto.value)
          .then(() => {
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('copyLink')})
            item.copy = true
            this.$forceUpdate()
            setTimeout(() => {
              item.copy = false
              this.$forceUpdate()
            }, 3000);
          })
          .catch(err => {
            this.$commons.updateSnackbar({show: true, color: 'success', text: err})
          });
      },
      copy: function (item, o) {
        // Seleciona o texto do input
        var texto = document.getElementById(`copy-link-shared-on-${item.id}-${o.value}`);

        // Seleciona o conteúdo do input
        texto.select();
        texto.setSelectionRange(0, 99999); // Para dispositivos móveis
        o.copy = true
        // Copia o texto para a área de transferência
        navigator.clipboard.writeText(texto.value)
          .then(() => {
            this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('copyLink')})
            o.copy = true
            this.$forceUpdate()
            setTimeout(() => {
              o.copy = false
              this.$forceUpdate()
            }, 3000);
          })
          .catch(err => {
            this.$commons.updateSnackbar({show: true, color: 'success', text: err})
          });
      },
    },
  }
</script>
<style scoped>
input:focus {
    outline: none; /* Remove a borda padrão do navegador */
    border: none;  /* Remove a borda definida */
}
</style>